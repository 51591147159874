"use strict";
/*
 USAGE:

 if (Feature.AWESOME_TEST_FEATURE) {
    // do cool stuff!
 } else {
    // don't do cool stuff..
 }

 ADD NEW FEATURE:
 add an entry (feature-name: min-version) to 'Feature._features.<new>'
 that's it!
*/

var Feature = {
    _features: {
        // feature-name: min-version
        AWESOME_TEST_FEATURE: "6.2.12.17",
        STATISTIC_FILE_FILTERED: "6.1.10.16",
        // the filtered command is available starting this version
        PUSH_NOTIFICATIONS: "7.1.9.24",
        ALARM_CLOCK: "6.5.6.1",
        CAR_CHARGER: "6.5.7.14",
        SMOKE_ALARM: "7.0.8.7",
        IRC_MANUAL_MODE_STATE: "7.3.2.14",
        AUDIO_ZONE__WAKE_AND_PLAY: "6.5.7.2",
        // Wrike 52159746
        API_KEY_REQUEST: "7.0",
        // approximately since version 7 available
        SECURE_HTTP_REQUESTS: "7.1.9.17",
        // sending requests with ?auth attached
        NFC_QR_QA_COMMANDS: "7.1.9.30",
        // uses feature SECURE_HTTP_REQUESTS, 9.30 is the next release
        REMOTE_PER_MODE_FAVPAD: "7.4.3.2",
        JAL_CONFIG_SERVICE_MODE_CMD: "7.4.3.16",
        // we should send a service mode command instead of "correct end position"
        ENCRYPTED_CONNECTION: "7.4.4.1",
        ENCRYPTED_CONNECTION_HTTP_USER: "8.1.10.4",
        // Miniserver bug, ?user= wasn't accepted
        ENCRYPTED_CONNECTION_FULLY: "8.1.10.14",
        // full connection (externally) will be encrypted (request)
        ENCRYPTED_SOCKET_CONNECTION: "7.4.4.1",
        CHANGE_PASSWORD: "7.4.4.1",
        // single users are allowed to change password
        CHANGE_USER_PASSWORDS_AND_CODE: "8.2.11.13",
        // password, visu pwd, code
        ALARM_SINGLE_COMMAND: "7.4.3.24",
        // uses one command to activate/deactivate (delayed) alarm
        // AUDIO_ZONE_SOURCE: enables the feature that the source state is filled correctly (we need a feature, because the source state is
        // per default always 0
        AUDIO_ZONE_SOURCE: "7.4.4.6",
        EXPERT_MODE: "8.0",
        EXPERT_MODE_FOR_GROUPS: "8.3.3.16",
        STRUCTURE_LIVE_UPDATES: "8.0",
        //I_ROOM_CONTROL_NOTIFICATIONS: "7.5.5.4",      // disabled, due to Miniserver spam bug (https://www.wrike.com/open.htm?id=109498590)
        POOL_CONTROL_NOTIFICATIONS: "8.0.7.12",
        AUTO_PILOT: "8.0",
        MULTI_MUSIC_SERVER: "7.5.5.18",
        // provides all infos that are needed (unless a zone is opened) via state updates
        REMOTE_CONTROL_RESET_COMMAND: "7.5.5.23",
        GEIGER_SLAT_ADJUSTMENT_RETRY: "7.5.6.2",
        CHANGE_PASSWORD_OPTIONAL: "7.5.6.6",
        // since this version we can selective allow the password change per user
        CHANGE_PASSWORDS: "7.5.6.27",
        VOLUME_STEP_SUPPORT: "7.5.5.17",
        UP_DOWN_LEFT_RIGHT_PULSE_SUPPORT: "7.5.6.16",
        INTERCOM_SIP_PASSWORD: "7.5.5.23",
        INTERCOM_TYPE: "7.5.5.31",
        INTERCOM_VERSION: "7.5.6.23",
        INTERCOM_OUTPUT_PULSE: "7.5.6.16",
        // outputs on intercom can be "pulsed"
        CAR_CHARGER_LIMIT_ALLOWED: "7.5.6.22",
        POOL_ERROR_HANDLING: "7.5.5.27",
        TTS_VIA_MINISERVER: "7.5.6.9",
        // TTS commands can be performed via miniserver & therefore via autopilot
        ALARM_CLOCK_NEXT_ENTRY_TIME: "7.4.3.23",
        LIGHT_CONTROL_PLUS_MINUS_CMD: "8.1.8.30",
        ENERGY_MONITOR_BATTERY: "8.1.10.11",
        // energy monitors can now handle batteries too
        ENERGY_MONITOR_9_STATS: "8.1.9.29",
        POOL_ERROR_STATE_OFFLINE: "8.1.10.24",
        // Offline error state of Pool, Wrike #99775789
        SECURE_DETAILS: "8.1.10.20",
        DETAILED_SERVER_STATE: "8.1.11.11",
        // 120869613: the miniserver now provides detailed booting states of the music server
        REQUEST_WEATHER_DATA: "8.3.1.30",
        // 112023455: the miniserver can be asked to re-request it's weather data.
        EDITABLE_OPERATING_MODES: "8.3.1.30",
        // 111818949: Operating Modes can now be scheduled from within the app.
        REPEAT_OP_MODE_MONTHLY: "8.3.2.23",
        // 111818949: Weekday-Based-Entries can be repeated for every month throughout the year.// 112023455: the miniserver can be asked to re-request it's weather data.
        LOCKABLE_WINDOWS: "8.3.3.2",
        // 133647699: Windows can also be locked or unlocked.
        BELL_EVENT_IMAGE_DETECTION: "8.3.3.8",
        // 134969401: an attribute in the details indicates that last bell events have images too.
        TOKENS: "9.0.7.25",
        // 129959854: instead of passwords, we now use tokens to authenticate.
        PERMISSION_HANDLING: "9.0.7.25",
        // 152296701: tokens now provide individual access rights to various features.
        EXPERT_MODE_LIGHT: "8.4.7.24",
        HOUSE_TAB: "9.0",
        DAYTIMER_RESET_STATE: "8.4.5.18",
        // 138725599: Activating the reset input permanently isn't visible on the app.
        TIME_UPDATES: "8.4.6.6",
        // 143678593: Miniserver now informs when its local time changes.
        IRC_STOP: "9.0.8.23",
        // 159798444: The irc can be stopped, meaning all of its heating and cooling outputs will be turned off.
        LCV2_USED_STATE: "9.0.8.30",
        // 172565560: The used state is a bitmap that tells if a mood is used for a specific purpose in the logic
        SMOKE_SIGNAL_AT_PREALARM: "9.0.8.30",
        // 173723117: The miniserver activates the visual alarm signal at an earlier stage in this version
        JALOUSIE_INFO_TEXT: "9.0.9.18",
        // 168962061: When a jalousie is locked or the safety is active, a infotext represents the cause
        LIGHTV2_AUTO_EVENTS: "9.0.9.20",
        // 178105819: Light V2 is now supported for providing events for the automatic designer.
        //TODO-woessto: adopt version requirement. 178105819
        ALARM_CLOCK_NIGHT_LIGHT: "9.2.12.6",
        ALARM_CLOCK_NIGHT_LIGHT_DEVICE_SETTINGS: "9.3.1.23",
        HASHES_ONLY: "9.1.12.3",
        // 159744071: (visu)Passwords are never transmitted, only (SHA1) hashes.
        VENT_CONTROL: "9.2.12.19",
        STEAK: "9.3.3.15",
        STEAK_NEW_DETAILS: "9.3.3.15",
        // TODO-goelzda: Set correct version
        IRCv2: "10.0.0.10",
        FULL_CLIMATE: "10.0.7.20",
        // From this version onwards, JWTs are handled using separate commands to ensure regular apps remain unchanged.
        POOL_OUT_OF_SERVICE_BY_APP: "10.1.11.10",
        // so far the pool could only be set out of order via an object input. Now it is available via App,
        POOL_ABORTABLE_STATE: "10.2.1.23",
        POOL_PUSH_REMOVED: "10.0.8.20",
        TOKEN_REFRESH_AND_CHECK: "10.0.9.13",
        // Tokens may now change when being refreshed. New webservice for checking token validity without changing them introduced
        DEV_PK_RESET: "10.2.3.13",
        // If a development PK is detected, it is being discarded & regenerated.
        JWT_SUPPORT: "10.1.12.11",
        CLIMATE_AVG_OUTDOOR_TEMP: "10.1.11.12",
        // Support of new average outdoor temperature state
        IRCv2_IS_INPUT_CONNECTED: "10.2.1.24",
        ADD_NFC_TAG_OVER_APP: "10.2.1.16",
        MUSIC_PARAM_CHECK: "10.2.1.30",
        // expert mode light allows checking for parameters.
        GLOBAL_HAS_INTERNET_STATE: "10.2.1.31",
        ARE_ALARM_SIGNALS_OFF: "10.2.1.30",
        MESSAGE_CENTER: "10.0.9.26",
        ALARM_CLOCK_ADVANCED_SOUND_OPTIONS: "10.2.1.16",
        // sound volume, volume sloping, sound selection
        ALARM_CLOCK_SNOOZE_TIMER_SETTING: "10.2.2.19",
        STEAK_DISPLAY_BRIGHTNESS: "9.3.7.26",
        STEAK_BATTERY_STATE: "10.2.1.16",
        USER_MANAGEMENT_REWORK: "10.3.5.23",
        LEARN_DEVICE_OVER_APP: "10.3.11.13",
        NFC_CODE_TOUCH_2FA: "10.3.6.5",
        USER_CAN_BE_DISABLED: "10.3.6.18",
        INITIAL_APP_INFO: "10.3.7.12",
        TIMEZONE_ID_INFO: "15.0.4.16",
        SHA256_COMMUNICATION: "10.4.0.0",
        START_DRILL: "10.3.7.31",
        WINDOW_CONTROL: "10.3.8.9",
        TEXT_INPUT: "10.3.9.6",
        SYSTEM_STATE_MS_UPDATE: "10.3.7.30",
        AAL_SMART_ALARM: "10.3.8.23",
        NEW_AUDIO_ZONE_STATES: "10.3.10.7",
        SHARED_USER_SETTINGS: "10.5.1.9",
        QUEUE_INDEX: "10.3.10.10",
        SUPPORTS_REQUEST_STATES_FOR: "10.3.11.13",
        MAILBOX: "10.5.1.20",
        AUDIO_CENTRAL_VOL_CONTROL: "10.3.11.25",
        CONTROL_RESTRICTIONS: "10.5.3.4",
        USER_MANAGEMENT_VALID_FROM: "10.5.3.19",
        AAL_EMERGENCY: "10.5.4.7",
        MS_PERMISSION_REWORK: "10.5.4.16",
        OPEN_SOURCE_LICENSES: "10.1.4.22",
        PRESENCE_DETECTOR: "10.5.6.19",
        LEARN_DEVICE_OVER_APP_REMAKE: "11.1.8.5",
        CORRECT_MS_UPDATE_VIA_SYSTEM_STATE: "11.2.11.20",
        DELETE_STATS_ON_DISABLE: "11.2.12.15",
        CONTROL_TEMPLATES: "11.3.1.1",
        RENAME_LIGHT_CIRCUITS: "11.3.1.26",
        AUTOMATIC_DESIGNER: "11.3.2.12",
        AUTOMATIC_DESIGNER_RAW_RESPONSE: "12.1.3.31",
        CONTROL_LOCK_HANDLING: "11.3.2.11",
        // lock controls with reason via API, unlock supported too.
        CREATE_ROOMS: "11.3.2.15",
        IRC_TEMP_LIMITS: "11.3.2.16",
        // support for setting a limit of the avg outside temp for heating/cooling on the IRC
        JALOUSIE_ENDPOSADJUST: "12.1.3.24",
        // support for adjusting the end positions of jalousies
        IRC_V2021: "12.1.3.12",
        // adoptions made to the irc v2 along with the the US session
        AUTOMATIC_DESIGNER_TASK_RECORDER: "12.1.4.12",
        AUTOMATIC_DESIGNER_SCENES: "12.1.4.16",
        AUTOMATIC_DESIGNER_RULES_ADDITIONAL_PROPS: "12.1.4.21",
        AUTOMATIC_DESIGNER_TASK_RECORDER_CONVERT_AS_POST: "12.1.4.26",
        NO_TASK_RECORDER: "12.1.4.21",
        ADDONS: "12.1.4.12",
        // support miniserver plugins
        HOME_KIT: "12.2.12.1",
        // support for Apple HomeKit
        TRUST: "12.1.5.17",
        LOAD_MANAGER: "12.1.5.19",
        AUTOMATIC_DESIGNER_SCENES_USE_VISU_PW: "12.1.5.21",
        ENERGY_MANAGER: "12.1.5.21",
        PULSE_AT: "12.1.6.2",
        PULSE_AT_TIME: "12.1.6.9",
        WINDOW_CENTRAL: "12.1.6.7",
        EXPERT_MODE_REFRESH: "12.1.5.31",
        MUSIC_SERVER_PROXY: "12.1.7.21",
        INTERCOM_GEN_2: "12.1.8.9",
        INTERCOM_SECURED_DETAILS: "12.2.9.3",
        GET_TOKEN_WITH_TOKEN: "12.2.10.6",
        // by providing "withToken=true" as query param, the MS checks the token hash instead of the pw hash for acquiruing new tokens.
        CHECK_TRUST_USER_TOKENS: "12.3.11.5",
        // BG-I12831 Version needs to be defined after fix from
        INTERCOM_GEN_1_BELL_STATE: "12.2.9.14",
        GLOBAL_CLOUD_STATES: "12.4.1.11",
        TOKEN_PERMISSION_NONE: "12.4.5.25",
        DYNAMIC_PROXY: "12.4.6.7",
        // it's now possible to add a port to the proxy-command (/proxy/uuid:port)
        PN_REGISTRATION_LIST: "12.4.6.23",
        // miniserver stores additional infos for PN registrations
        DAYLIGHT: "12.4.6.22",

        ENERGY_FLOW_MONITOR: "13.1.9.9",
        METER_REWORK: "13.1.9.9",
        ENERGY_MANAGER_V2: "13.1.9.9",
        EM2_RESET_TO_AUTO: "13.1.11.2",
        TREE_FOUND_DEVICES: "13.1.11.30", // tree device search already provides number of found devices in extension-list
        EASY_IDENTIFY_STOP: "13.2.12.5", // jdev/sps/livelearn/identifystop supported.
        LIGHT_GROUP_IDENTIFY: "13.2.12.7",
        USER_MANAGEMENT_USER_INFOS: "13.2.12.12",
        RADIO_NEXT_PREV: "13.3.1.10",

        DEV_SEARCH_RAW_EXTENSIONS_LIST: "13.3.2.23", // returns a raw list to avoid json escaping when wrapped into result.
        DEV_SEARCH_CLIENT_SPECIFICATION: "13.3.2.23", // returns a raw list to avoid json escaping when wrapped into result.
        JALOUSIE_UP_DOWN_PULSE: "13.3.3.20", // On a short tap on up or down in the blinds control a pulse can be sent instead of up and upoff command
        USER_EXPIRATION_ACTION: "14.2.5.17",
        WALLBOX2_UPDATED_BLOCK: "14.2.5.26",
        AC_CONTROL_UPDATES: "14.2.6.13",
        IRC_SCHEDULE_SETPOINTS: "14.2.6.13",
        GATE_PARTIAL_OPEN: "14.2.6.12",
        USER_MANAGEMENT_ADDITIONAL_USER_FIELDS: "14.3.4.26", // user-management: customfields, department, ...
        WALLBOX_SWITCHBOARDS: "14.3.7.13",
        CLIENT_LIGHTGROUP_SUPPORT: "14.3.7.13",
        GW_DEVICESEARCH_OPTIONS: "14.3.7.28",
        CLIMATE_CONTROLLER_OFF: "14.4.9.5",
        TRUST_STRUCTURE_REQUEST: "14.4.9.7",
        BEARER_TOKEN_PARAMETER: "14.4.9.7",
        WALLBOX_EM: "14.5.11.2",
        TRUST_JWT_AUTH: "14.5.11.3", // separate permission needs to be set when authenticating on another trusted MS
        APP_PAIRING: "14.5.11.29",
        SET_DATE_TIME_UTC: "14.2.5.23",
        NFC_CODE_TOUCH_DYNAMIC_AUTH_TYPE: "15.0.0.0",
        GENERIC_DEVICE_SEARCH: "15.0.5.7",
        BRANDING_SVG_BROKEN: "14.7.3.6",
        BRANDING_SVG_FIXED: "15.0.5.12",
        DEVICE_SEARCH_HARDWARE_VARIANTS: "15.0.5.22",
        AC_CONTROL_UPDATES_V2: "15.1.7.25",
        SYSTEM_SCHEME_EXTENSIONS: "15.1.7.23",
        HVAC_CLIMATE_CONTROLLER_INTEGRATION: "15.1.7.25",
        WALLBOX2_OCPP_SUPPORT: "15.1.0.0",
        AC_CONTROL_AUTO_FANSPEED: "15.1.8.1",
        SYSTEM_SCHEME_ACTION_VISIBILITY: "15.1.8.1"
    },

    /**
     * @param {ConfigVersion|string} configVersion
     */
    update: function updateFeatures(configVersion) {
        configVersion = new ConfigVersion(configVersion);

        for (var featureKey in this._features) {
            if (this._features.hasOwnProperty(featureKey)) {
                this[featureKey] = configVersion.greaterThanOrEqualTo(this._features[featureKey]);
            }
        }
    },
    getFeaturesForVersion: function getFeaturesForVersion(configVersion) {
        configVersion = new ConfigVersion(configVersion);
        var features = [];

        for (var featureKey in this._features) {
            if (this._features.hasOwnProperty(featureKey)) {
                if (configVersion.greaterThanOrEqualTo(this._features[featureKey])) {
                    features.push(featureKey);
                }
            }
        }

        return features;
    },

    hasFeature: function hasFeature(configVersion, featureVersion) {
        let cfg = new ConfigVersion(configVersion),
            feat = new ConfigVersion(featureVersion);

        return cfg.greaterThanOrEqualTo(feat);
    }
};
Feature.update("0"); // set all features to false
